import { currentProfileCachePath } from 'apis/profile';
import { getAllKeysFromIdb, removeValueFromIdb } from './IndexDB';

export const getValueFromSession = (label: string): any => {
  const value = localStorage.getItem(label);

  try {
    if (value) return JSON.parse(value);
    return null;
  } catch (err) {
    return null;
  }
};

export const setValueInSession = (label: string, value: any): void => {
  localStorage.setItem(label, JSON.stringify(value));
};

export const removeValueFromSession = (label: string): void => {
  localStorage.removeItem(label);
};

export const getValueFromLocal = (label: string): any => {
  const value = localStorage.getItem(label);
  try {
    if (value) return JSON.parse(value);
    return null;
  } catch (err) {
    return null;
  }
};

export const setValueInLocal = (label: string, value: any, cb?: any): void => {
  localStorage.setItem(label, JSON.stringify(value));
  if (cb) {
    cb();
  }
};

export const removeValueFromLocal = (label: string): void => {
  localStorage.removeItem(label);
};

export const clearStorage = () => {
  Object.keys(localStorage).forEach((key) => {
    console.log(key);
    if (key !== 'lastVisitedProfileUrl') {
      localStorage.removeItem(key);
    }
  });
};
export const clearStorageOnLogin = async () => {
  const lastVisitedProfile = localStorage.getItem('lastVisitedProfileUrl');
  const currentUser = currentProfileCachePath();
  const keyMatched: string[] = [
    'auth',
    'metatag',
    'userRoles',
    'userImpersonation',
    'lastVisitedProfileUrl',
    'trackScreens',
    'trackProfiles',
    'loginFailedAttempts',
  ];
  const keyIncludeLocalStroge: string[] = [];
  const keyIncludeIDB: string[] = [];

  if (currentUser) {
    keyIncludeLocalStroge.push(currentUser);
    keyIncludeIDB.push(`og-${currentUser.slice('cachedProfile-'.length)}`);
  }

  if (lastVisitedProfile) {
    keyIncludeIDB.push(`og-${lastVisitedProfile}`);
    keyIncludeLocalStroge.push(`cachedProfile-${lastVisitedProfile}`);
  }

  Object.keys(localStorage).forEach((key) => {
    if (key) {
      if (
        !keyMatched.includes(key) &&
        !keyIncludeLocalStroge.some((includedKey) => key.includes(includedKey))
      ) {
        localStorage.removeItem(key);
      }
    }
  });
  const testRemove = async () => {
    const allKeys = await getAllKeysFromIdb();
    for (const key of allKeys) {
      if (!keyIncludeIDB.some((idbKey) => key.includes(idbKey))) {
        await removeValueFromIdb(key);
        // console.log(`Removed keys: ${key}`);
      }
    }
  };

  await testRemove();
};
export const clearSession = () => {
  sessionStorage.clear();
};

export const clearBrowser = () => {
  clearStorage();
  sessionStorage.clear();
};

export const removeAuthFromStorage = () => {
  localStorage.removeItem('auth');
  sessionStorage.removeItem('auth');
};

/**
 * Function to get value from localStorage and sessionStorage. If date passes expire date, remove storage.
 * @returns value | null
 */
export const getAuthWithExpire = (): any => {
  const now = new Date();
  const localValue = getValueFromLocal('auth');
  if (localValue) {
    if (now.getTime() > localValue.expiry) {
      removeAuthFromStorage();
      return null;
    }
    return localValue.value;
  } else {
    const sessionValue = getValueFromSession('auth');
    if (sessionValue) {
      if (now.getTime() > sessionValue.expiry) {
        removeAuthFromStorage();
        return null;
      }
      return sessionValue.value;
    }
  }
  return null;
};

interface SetAuthWithExpireProps {
  value: any;
  isRemember: boolean;
  expireTTL: number;
}

/**
 * Function to store auth value with expiration time.
 * @param value: Object, isRemeber: boolean, expireTTL: number
 */
export const setAuthWithExpire = ({
  value,
  isRemember,
  expireTTL,
}: SetAuthWithExpireProps): void => {
  const now = new Date();
  const expireSessioinValue = {
    value,
    expiry: now.getTime() + 2592000 * 1000,
  };

  setValueInSession('auth', expireSessioinValue);

  if (isRemember) {
    const expireValue = {
      value,
      expiry: now.getTime() + expireTTL * 1000,
    };

    setValueInLocal('auth', expireValue);
  }
};
