import React, { useState, useCallback } from 'react';
import CacheBuster from 'react-cache-buster';
import Routers from 'routers';
import Packages from '../package.json';

const App = () => {
  //   const isProduction = process.env.NODE_ENV === 'production';
  const { version } = Packages;
  console.log(version, 'App Version');
  const [isCacheBusterEnabled, setCacheBusterEnabled] = useState<boolean>(false);

  const cacheBusterFeatureFlag = useCallback(
    ({
      isCacheBusterEnabled,
      isCacheBusterLoading,
    }: {
      isCacheBusterEnabled: boolean;
      isCacheBusterLoading: boolean;
    }) => {
      setCacheBusterEnabled(isCacheBusterEnabled && !isCacheBusterLoading);
    },
    [setCacheBusterEnabled]
  );

  if (isCacheBusterEnabled) {
    return (
      <CacheBuster
        currentVersion={version}
        isEnabled={isCacheBusterEnabled} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      >
        <Routers />
      </CacheBuster>
    );
  }
  return <Routers cacheBusterFeatureFlag={cacheBusterFeatureFlag} />;
};

export default App;
