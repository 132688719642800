import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';

import {
  getProfileImage,
  getProfileThumbnailRequest,
  getCompanyEntity,
  getRepeatableFields,
  getCompanyLayoutForType,
  getCompanyLogos,
  getCurrentUserCachedProfile,
} from 'apis/profile';
import { getFile } from 'apis/file';
import {
  ProfileProps,
  QLink,
  CustomParagraph,
  AwardCertificationItem,
  RepeatablePhoneNumber,
  RepeatableEmailAddress,
  RepeatableWebsite,
  CompanyLogoEntity,
  RepeatableWorkScheduleDay,
  Language,
  Testimonial,
} from 'types/profile';
import { User } from 'types/companyDirectory';
import { ReorderFieldType } from 'types/reorder';
import { updateProfileAPI, getProfileByUID } from 'apis/profile';
import { updateParagraphAPI } from 'apis/paragraph';
import { setValueInLocal, getValueFromLocal } from 'utils/storage';
import { convertImageUrlToDataURL } from 'utils/image';
import {
  orderSocials,
  orderContactDetails,
  orderCustomSectionLinks,
  syncOrders,
  orderFeaturedLinks,
  orderWebsites,
} from 'utils/orders';
import { isEmptyArray } from 'formik';
import { DefaultColors } from 'constants/colors';
import { useFeatureFlag } from 'configcat-react';

export interface CompanyTheme {
  qrcodeColor?: string;
  ctaColor: string;
  secondaryCtaColor?: string;
  headerBgImgDesktop?: any;
  headerBgImgMobile?: any;
  headerBgColor: string;
  headerTextColor: string;
  headerIconColor: string;
  sectionDividerBgColor?: string;
  sectionDividerTextColor?: string;
  primaryColor: string;
  secondaryColor?: string;
  iconColor: string;
  shareIconColor?: string;
  socialIconColor?: string;
  secondaryIconColor?: string;
  shortcutIconColor?: string;
  caretIconColor?: string;
  googleWalletHeroImg?: any;
  bgProfileColor?: string;
}
const DefaultTheme: CompanyTheme = {
  qrcodeColor: '#000000',
  ctaColor: '#8544d8',
  secondaryCtaColor: '#ffffff',
  // headerBgImgDesktop?: any;
  // headerBgImgMobile?: any;
  headerBgColor: '#8544d8',
  headerTextColor: '#ffffff',
  headerIconColor: '#ffffff',
  sectionDividerBgColor: '#F0F1F2',
  sectionDividerTextColor: '#1f1f1f',
  primaryColor: '#1f1f1f',
  secondaryColor: '#545454',
  iconColor: '#8544d8',
  shareIconColor: '#8544D8',
  socialIconColor: '#8544D8',
  secondaryIconColor: '#8544d8',
  shortcutIconColor: '#8544d8',
  caretIconColor: '#8544d8',
  // googleWalletHeroImg?: any;
  bgProfileColor: '#FFFFFF',
};
export interface CompanyEntity {
  paperviewLayout: string;
  backFlipLayout: string;
  profileHeaderLayout: string;
  fullProfileHeaderLayout: string;
  companyLogos: CompanyLogoEntity[] | null;
  zoomBgLayout: string;
  companyEntityLogoUrl: string | null;
  companyEntityLogoSquareUrl: string | null;
  gwalletHeroImgUrl: string | null;
  theme: CompanyTheme;
  title?: string;
}

interface ContextType {
  profile: ProfileProps | null;
  profileImgUrl: string | null;
  profileThumbnailUrl: string | null;
  companyImgUrl: string | null;
  namePronuciationUrl: string | null;
  companyEntity: CompanyEntity | null;
  companyTheme?: CompanyTheme;
  defaultTheme?: CompanyTheme;
  companyLogoSquareUrl: string | null;
  gwalletHeroImgUrl: string | null;
  featuredLinks: QLink[] | null;
  resources: QLink[] | null;
  customSections: CustomParagraph[] | null;
  awardsCertifications: AwardCertificationItem[] | null;
  repeatablePhoneNumbers: RepeatablePhoneNumber[] | null;
  repeatableEmailAddress: RepeatableEmailAddress[] | null;
  repeatableWebsites: RepeatableWebsite[] | null;
  loadingProfile: boolean;
  setLoadingProfile: Dispatch<SetStateAction<boolean>>;
  setProfile: Dispatch<SetStateAction<ProfileProps | null>>;
  updateProfile: (
    uid: number,
    body: any,
    paragraphBody?: any,
    callback?: (values: any) => Promise<void>
  ) => any;
  activePhoneNumber: string;
  activeTextNumber: string;
  activeEmail: string;
  activeWebsite: string;
  headerFieldsOrder: ReorderFieldType[];
  detailFieldsOrder: ReorderFieldType[];
  setDetailFields: Dispatch<SetStateAction<ReorderFieldType[]>>;
  workScheduleDays: RepeatableWorkScheduleDay[] | null;
  languages: Language[] | null;
  testimonials: Testimonial[] | null;
  isDataCached: boolean;
  impersonateUserAlias: User | null;
  setImpersonateUserAlias: Dispatch<SetStateAction<User | null>>;
  showImpersonateUserSnackbar: boolean;
  setShowImpersonateUserSnackbar: Dispatch<SetStateAction<boolean>>;
  isColleaguesUpdated: boolean;
  setIsColleaguesUpdated: Dispatch<SetStateAction<boolean>>;
  isProfileImageLoading: boolean;
  setIsProfileImageLoading: Dispatch<SetStateAction<boolean>>;
}

const profileContext = createContext<ContextType>({
  profile: null,
  profileImgUrl: null,
  profileThumbnailUrl: null,
  companyImgUrl: null,
  namePronuciationUrl: null,
  companyEntity: null,
  companyLogoSquareUrl: null,
  gwalletHeroImgUrl: '',
  featuredLinks: null,
  resources: null,
  customSections: null,
  companyTheme: undefined,
  awardsCertifications: null,
  repeatablePhoneNumbers: null,
  languages: null,
  testimonials: null,
  repeatableEmailAddress: null,
  repeatableWebsites: null,
  loadingProfile: false,
  setLoadingProfile: () => {},
  setProfile: () => {},
  updateProfile: () => {},
  activePhoneNumber: '',
  activeTextNumber: '',
  activeEmail: '',
  activeWebsite: '',
  headerFieldsOrder: [],
  detailFieldsOrder: [],
  setDetailFields: () => {},
  workScheduleDays: [],
  isDataCached: false,
  impersonateUserAlias: null,
  setImpersonateUserAlias: () => {},
  showImpersonateUserSnackbar: false,
  setShowImpersonateUserSnackbar: () => {},
  isColleaguesUpdated: false,
  setIsColleaguesUpdated: () => {},
  isProfileImageLoading: false,
  setIsProfileImageLoading: () => {},
});

export const ProfileProvider = ({
  cacheBusterFeatureFlag,
  children,
}: {
  cacheBusterFeatureFlag?: ({
    isCacheBusterEnabled,
    isCacheBusterLoading,
  }: {
    isCacheBusterEnabled: boolean;
    isCacheBusterLoading: boolean;
  }) => void;
  children: React.ReactNode | React.ReactNodeArray;
}) => {
  const [profile, setProfile] = useState<ProfileProps | null>(null);
  const [profileImgUrl, setProfileImgUrl] = useState<string | null>(null);
  const [isProfileImageLoading, setIsProfileImageLoading] = useState<boolean>(true);
  const [profileThumbnailUrl, setProfileThumbnailUrl] = useState<string | null>(
    null
  );
  const [showImpersonateUserSnackbar, setShowImpersonateUserSnackbar] =
    useState<boolean>(false);

  const [impersonateUserAlias, setImpersonateUserAlias] = useState<User | null>(
    null
  );
  const [isColleaguesUpdated, setIsColleaguesUpdated] = useState<boolean>(false);
  const [companyImgUrl, setCompanyImgUrl] = useState<string | null>(null);
  const [namePronuciationUrl, setNamePronunciationUrl] = useState<string | null>(
    null
  );

  const [companyLogoSquareUrl, setCompanyLogoSquareUrl] = useState<string | null>(
    null
  );
  const [gwalletHeroImgUrl, setGwalletHeroImgUrl] = useState<string | null>(null);
  const [featuredLinks, setFeaturedLinks] = useState<QLink[] | null>(null);
  const [resources, setResources] = useState<QLink[] | null>(null);
  const [customSections, setCustomSections] = useState<CustomParagraph[] | null>(
    null
  );
  const [awardsCertifications, setAwardsCertifications] = useState<
    AwardCertificationItem[] | null
  >(null);
  const [languages, setLanguages] = useState<Language[] | null>(null);
  const [testimonials, setTestimonials] = useState<Testimonial[] | null>(null);
  const [loadingProfile, setLoadingProfile] = useState<boolean>(false);
  const [caching, setCaching] = useState<boolean>(false);
  const [companyEntity, setCompanyEntity] = useState<CompanyEntity | null>(null);
  const [repeatablePhoneNumbers, setRepeatablePhoneNumbers] = useState<
    RepeatablePhoneNumber[] | null
  >(null);
  const [repeatableEmailAddress, setRepeatableEmailAddress] = useState<
    RepeatableEmailAddress[] | null
  >(null);
  const [repeatableWebsites, setRepeatableWebsites] = useState<
    RepeatableWebsite[] | null
  >(null);
  const [fieldOrders, setFieldOrders] = useState<ReorderFieldType[] | null>(null);
  const [headerFields, setHeaderFields] = useState<ReorderFieldType[]>([]);
  const [detailFields, setDetailFields] = useState<ReorderFieldType[]>([]);

  const { value: isCacheBusterEnabled, loading: isCacheBusterLoading } =
    useFeatureFlag('isCacheBusterEnabled', false);

  useEffect(() => {
    !!cacheBusterFeatureFlag &&
      cacheBusterFeatureFlag({
        isCacheBusterEnabled,
        isCacheBusterLoading,
      });
  }, [isCacheBusterEnabled, isCacheBusterLoading]);

  const [workScheduleDays, setWorkScheduleDays] = useState<
    RepeatableWorkScheduleDay[] | null
  >(null);

  const [isDataCached, setIsDataCached] = useState<boolean>(false);
  const fetchProfileImage = useCallback(async (uid: number) => {
    const response = await getProfileImage(uid);
    setProfileImgUrl(response);
    setIsProfileImageLoading(false);
  }, []);

  const fetchProfileThumbnail = useCallback(async (uid: number) => {
    const response = await getProfileThumbnailRequest(uid);
    setProfileThumbnailUrl(response);
  }, []);

  const fetchCompanyImage = useCallback(async (id: number) => {
    try {
      const response = await getFile(id);
      if (response) {
        const dataURL = await convertImageUrlToDataURL(
          process.env.REACT_APP_BASE_API + response
        );
        setCompanyImgUrl(dataURL);
      }
      return null;
    } catch (error) {
      const cachedProfile = getValueFromLocal(
        `cachedProfile-${profile?.path.alias}`
      );
      if (cachedProfile?.profile?.field_company_logo?.target_id === id) {
        setCompanyImgUrl(cachedProfile?.companyImgUrl);
      }
      console.log(error);
      return null;
    }
  }, []);

  const fetchNamePronunciation = useCallback(async (id: number) => {
    const response = await getFile(id);
    if (response) {
      setNamePronunciationUrl(response);
    } else {
      const cachedProfile = getValueFromLocal(
        `cachedProfile-${profile?.path.alias}`
      );
      if (cachedProfile?.profile?.field_name_pronunciation?.target_id === id) {
        setNamePronunciationUrl(cachedProfile?.namePronuciationUrl);
      }
    }
  }, []);

  const fetchCompanySquareImage = useCallback(async (id: number) => {
    const response = await getFile(id);
    if (response) {
      setCompanyLogoSquareUrl(response);
    } else {
      const cachedProfile = getValueFromLocal(
        `cachedProfile-${profile?.path.alias}`
      );
      if (cachedProfile?.profile?.field_company_logo_square?.target_id === id) {
        setCompanyLogoSquareUrl(cachedProfile?.companyLogoSquareUrl);
      }
    }
  }, []);

  const fetchCompanyEntity = async (url: string, id: number) => {
    const data = await getCompanyEntity(url);
    const responseLogo = await getFile(data?.field_company_logo?.[0]?.target_id);
    const responseLogoSquare = await getFile(
      data?.field_company_logo_square?.[0]?.target_id
    );
    // company theme images
    const headerBgDesktop = await getFile(
      data?.field_header_bg_img_desktop?.[0]?.target_id
    );
    const headerBgMobile = await getFile(
      data?.field_header_bg_img_mobile?.[0]?.target_id
    );

    // set default colors for company
    const qrcodeColor =
      data?.field_qrcode_color?.[0]?.color || DefaultTheme.qrcodeColor;
    const ctaColor = data?.field_cta_color?.[0]?.color || DefaultTheme.ctaColor;
    const secondaryCtaColor =
      data?.field_secondary_cta_color?.[0]?.color || DefaultTheme.secondaryCtaColor;
    const headerBgColor =
      data?.field_header_bg_color?.[0]?.color || DefaultTheme.headerBgColor;
    const headerTextColor =
      data?.field_header_text_color?.[0]?.color || DefaultTheme.headerTextColor;
    const headerIconColor =
      data?.field_profile_header_icon_color?.[0]?.color ||
      DefaultTheme.headerIconColor;
    const sectionDividerBgColor =
      data?.field_section_divider_color?.[0]?.color ||
      DefaultTheme.sectionDividerBgColor;
    const sectionDividerTextColor =
      data?.field_headertext_color?.[0]?.color ||
      DefaultTheme.sectionDividerTextColor;
    const primaryColor =
      data?.field_primary_color?.[0]?.color || DefaultTheme.primaryColor;
    const secondaryColor =
      data?.field_secondary_color?.[0]?.color || DefaultTheme.secondaryColor;
    const iconColor = data?.field_icon_color?.[0]?.color || DefaultTheme.iconColor;
    const shareIconColor =
      data?.field_icon_share_color?.[0]?.color || DefaultTheme.shareIconColor;
    const socialIconColor =
      data?.field_icon_social_color?.[0]?.color || DefaultTheme.socialIconColor;
    const secondaryIconColor =
      data?.field_secondaryicon_color?.[0]?.color || DefaultTheme.secondaryIconColor;
    const shortcutIconColor =
      data?.field_icon_shortcuts_color?.[0]?.color || DefaultTheme.shortcutIconColor;
    const caretIconColor =
      data?.field_caret_color?.[0]?.color || DefaultTheme.caretIconColor;
    const bgProfileColor =
      data?.field_bg_profile_color?.[0]?.color || DefaultTheme.bgProfileColor;

    const responseGwalletHeroImg = await getFile(
      data?.field_gwallet_hero_img?.[0]?.target_id
    );
    const paperviewLayout: any = await getCompanyLayoutForType(id, 'paper_view');
    const backFlipLayout: any = await getCompanyLayoutForType(
      id,
      'paper_view_back_flip'
    );
    const companyLogos = await getCompanyLogos(id);
    const profileHeaderLayout: any = await getCompanyLayoutForType(
      id,
      'profile_header'
    );
    const fullProfileHeaderLayout: any = await getCompanyLayoutForType(
      id,
      'full_profile_header'
    );

    setCompanyEntity((prev) => {
      return {
        ...prev,
        paperviewLayout: paperviewLayout?.field_layout_html,
        backFlipLayout: backFlipLayout?.field_layout_html,
        profileHeaderLayout: profileHeaderLayout?.field_layout_html,
        fullProfileHeaderLayout: fullProfileHeaderLayout?.field_layout_html,
        companyLogos: companyLogos,
        title: data?.title[0]?.value,
        zoomBgLayout: data?.field_zoombg_layout?.[0]?.value,
        companyEntityLogoUrl: responseLogo,
        companyEntityLogoSquareUrl: responseLogoSquare,
        gwalletHeroImgUrl: responseGwalletHeroImg,

        theme: {
          qrcodeColor: qrcodeColor,
          ctaColor: ctaColor,
          secondaryCtaColor: secondaryCtaColor,
          headerBgImgDesktop: headerBgDesktop,
          headerBgImgMobile: headerBgMobile,
          headerBgColor: headerBgColor,
          headerTextColor: headerTextColor,
          headerIconColor: headerIconColor,
          sectionDividerBgColor: sectionDividerBgColor,
          sectionDividerTextColor: sectionDividerTextColor,
          primaryColor: primaryColor,
          secondaryColor: secondaryColor,
          iconColor: iconColor,
          shareIconColor: shareIconColor,
          socialIconColor: socialIconColor,
          secondaryIconColor: secondaryIconColor,
          shortcutIconColor: shortcutIconColor,
          caretIconColor: caretIconColor,
          bgProfileColor: bgProfileColor,
        },
      };
    });
  };

  const fetchRepeatableFields = useCallback(async (id: number) => {
    const response = await getRepeatableFields(id);
    setFeaturedLinks(
      isEmptyArray(response?.field_featured_links) ||
        response?.field_featured_links[0].field_link_label === null
        ? [
            {
              field_name: 'header_add_to_contacts',
              title: 'Add To Contacts',
              is_visible: true,
            },
          ]
        : response?.field_featured_links
    );
    setAwardsCertifications(response?.field_awards_certs);
    setRepeatableEmailAddress(response?.field_contact_detail_emails);
    setRepeatablePhoneNumbers(response?.field_contact_detail_numbers);
    setRepeatableWebsites(response?.field_contact_detail_websites);
    setCustomSections(response?.field_custom_sections);
    setResources(response?.field_resources);
    setFieldOrders(response?.field_reordering);
    setWorkScheduleDays(response?.field_work_schedule_days);
    setLanguages(response?.field_languages);
    setTestimonials(response?.field_testimonials);
    return response;
  }, []);

  const initProfileDetails = () => {
    setProfileImgUrl(null);
    setCompanyImgUrl(null);
    setCompanyLogoSquareUrl(null);
    setNamePronunciationUrl(null);
    setGwalletHeroImgUrl(null);
    setFeaturedLinks(null);
    setResources(null);
    setCustomSections(null);
    setAwardsCertifications(null);
    setRepeatablePhoneNumbers(null);
    setRepeatableEmailAddress(null);
    setRepeatableWebsites(null);
    setFieldOrders(null);
    setHeaderFields([]);
    setDetailFields([]);
    setWorkScheduleDays([]);
    setLanguages(null);
    setTestimonials(null);
  };

  const fetchProfileDetails = useCallback(
    async (profile: ProfileProps) => {
      setLoadingProfile(true);
      initProfileDetails();
      const fetchArray: Promise<any>[] = [];
      if (profile.uid) {
        fetchArray.push(fetchProfileImage(profile.uid));
        fetchArray.push(fetchProfileThumbnail(profile.uid));
        fetchArray.push(fetchRepeatableFields(profile.uid));
      }
      // if (profile.field_company_logo?.target_id) {
      //   fetchArray.push(fetchCompanyImage(profile?.field_company_logo?.target_id));
      // }
      // if (profile.field_company_logo_square?.target_id) {
      //   fetchArray.push(
      //     fetchCompanySquareImage(profile?.field_company_logo_square?.target_id)
      //   );
      // }
      if (profile.field_name_pronunciation?.target_id) {
        fetchArray.push(
          fetchNamePronunciation(profile?.field_name_pronunciation?.target_id)
        );
      }
      if (profile.field_company_entity && profile.field_company_entity.url) {
        fetchArray.push(
          fetchCompanyEntity(
            profile.field_company_entity.url,
            profile.field_company_entity.target_id
          )
        );
      }
      Promise.allSettled(fetchArray)
        .then(() => {
          setCaching(true);
          setLoadingProfile(false);
        })
        .catch(() => {
          setLoadingProfile(false);
        });
    },
    [
      profile,
      fetchProfileImage,
      fetchProfileThumbnail,
      fetchCompanyImage,
      fetchCompanySquareImage,
      fetchNamePronunciation,
      fetchRepeatableFields,
    ]
  );
  const cacheProfileInfo = useCallback(async () => {
    const currentCachedProfile = getCurrentUserCachedProfile();
    let cachedProfile = { ...currentCachedProfile };
    if (!cachedProfile) {
      cachedProfile = {};
    }

    // Update only the required values
    if (profile) {
      cachedProfile.profile = profile;
    }
    if (profileImgUrl) {
      cachedProfile.profileImgUrl = await convertImageUrlToDataURL(profileImgUrl);
    }
    if (companyImgUrl) {
      cachedProfile.companyImgUrl = await convertImageUrlToDataURL(companyImgUrl);
    }
    if (namePronuciationUrl) {
      cachedProfile.namePronuciationUrl =
        await convertImageUrlToDataURL(namePronuciationUrl);
    }
    if (companyLogoSquareUrl) {
      cachedProfile.companyLogoSquareUrl =
        await convertImageUrlToDataURL(companyLogoSquareUrl);
    }
    if (gwalletHeroImgUrl) {
      cachedProfile.gwalletHeroImgUrl =
        await convertImageUrlToDataURL(gwalletHeroImgUrl);
    }
    if (featuredLinks) {
      cachedProfile.featuredLinks = featuredLinks;
    }
    if (resources) {
      cachedProfile.resources = resources;
    }
    if (customSections) {
      cachedProfile.customSections = customSections;
    }
    if (awardsCertifications) {
      cachedProfile.awardsCertifications = awardsCertifications;
    }
    if (repeatableEmailAddress) {
      cachedProfile.repeatableEmailAddress = repeatableEmailAddress;
    }
    if (repeatablePhoneNumbers) {
      cachedProfile.repeatablePhoneNumbers = repeatablePhoneNumbers;
    }
    if (languages) {
      cachedProfile.languages = languages;
    }
    if (testimonials) {
      cachedProfile.testimonials = testimonials;
    }
    if (repeatableWebsites) {
      cachedProfile.repeatableWebsites = repeatableWebsites;
    }
    if (headerFields && headerFields.length > 0) {
      cachedProfile.headerFields = headerFields;
    }
    if (detailFields && detailFields.length > 0) {
      cachedProfile.detailFields = detailFields;
    }
    if (companyEntity) {
      cachedProfile.companyEntity = {
        ...cachedProfile.companyEntity,
        ...companyEntity,
      };
    }
    if (workScheduleDays) {
      cachedProfile.workScheduleDays = workScheduleDays;
    }

    // Set the updated object in localStorage
    // console.log('cachedProfile in update', cachedProfile);

    if (profile?.path.alias) {
      setValueInLocal(`cachedProfile-${profile?.path.alias}`, cachedProfile, () =>
        setIsDataCached((prev) => !prev)
      );
    }
  }, [
    profile,
    profileImgUrl,
    companyImgUrl,
    namePronuciationUrl,
    companyLogoSquareUrl,
    gwalletHeroImgUrl,
    featuredLinks,
    resources,
    customSections,
    awardsCertifications,
    repeatableEmailAddress,
    repeatablePhoneNumbers,
    repeatableWebsites,
    headerFields,
    detailFields,
    companyEntity,
    workScheduleDays,
    languages,
    testimonials,
  ]);

  const reFetchProfileData = async (profile: ProfileProps) => {
    await fetchProfileDetails(profile);
  };

  useEffect(() => {
    if (profile && fieldOrders) {
      const headerFieldsTemp: ReorderFieldType[] = [];
      const detailsFieldsTemp: ReorderFieldType[] = [];
      headerFieldsTemp.push({
        field_name: 'header_featured_links',
        field_subfields:
          (featuredLinks?.length &&
            fieldOrders &&
            orderFeaturedLinks(featuredLinks, fieldOrders)) ||
          undefined,
        title: 'Action Buttons',
        is_visible: true,
      });
      headerFieldsTemp.push({
        field_name: 'header_social_links',
        field_subfields: orderSocials(profile),
        title: profile.field_social_links_section_title || 'Social Links',
        is_visible: true,
      });
      // console.log('headerFieldsTemp', headerFieldsTemp)
      detailsFieldsTemp.push({
        field_name: 'details_about_me',
        title: profile.aboutme_section_title || 'About Me',
        is_visible: true,
      });

      detailsFieldsTemp.push({
        field_name: 'details_contact_details',
        field_subfields: orderContactDetails(
          profile,
          repeatableEmailAddress,
          repeatablePhoneNumbers
        ),
        title: 'Contact Details',
        is_visible: true,
      });

      detailsFieldsTemp.push({
        field_name: 'details_websites',
        field_subfields: orderWebsites(repeatableWebsites),
        title: profile.field_websites_section_title || 'Websites',
        is_visible: true,
      });

      detailsFieldsTemp.push({
        field_name: 'details_languages',
        field_subfields:
          languages && languages.length
            ? languages.map((item) => ({
                field_name: 'details_languages:' + item.id,
                title: item.field_language_user,
                is_visible: true,
              }))
            : [],
        title: profile.field_languages_section_title || 'Languages',
        is_visible: true,
      });
      detailsFieldsTemp.push({
        field_name: 'details_testimonials',
        field_subfields:
          testimonials && testimonials.length
            ? testimonials.map((item) => ({
                field_name: 'details_testimonials:' + item.id,
                title: item.field_testimonial_text,
                is_visible: true,
                id: item.id,
              }))
            : [],
        title: profile.field_testimonials_section_title || 'Testimonials',
        is_visible: true,
      });
      detailsFieldsTemp.push({
        field_name: 'details_field_work_schedule',
        title: profile.field_work_sched_sect_title || 'My Work Schedule',
        is_visible: true,
      });
      detailsFieldsTemp.push({
        field_name: 'details_awards_certifications',
        field_subfields:
          awardsCertifications && awardsCertifications.length
            ? awardsCertifications.map((item) => ({
                field_name: 'details_awards_certifications:' + item.id,
                icon: item.field_badge_logo_64_64
                  ? item.field_badge_logo_64_64
                  : item.field_badge_logo,
                title: item.field_badge_name,
                description: item.field_award_description,
                is_visible: item.field_is_visible,
                link: item.field_award_cert_url,
              }))
            : [],
        title: profile.field_awards_title || 'Awards and Certification',
        is_visible: true,
      });
      if (isEmptyArray(customSections)) {
        detailsFieldsTemp.push({
          field_name: 'details_custom_sections',
          title: 'Custom Section Detail',
          is_visible: true,
        });
      } else {
        customSections?.forEach((custom) => {
          detailsFieldsTemp.push({
            field_name: 'details_custom_sections:' + custom.id,
            field_subfields: orderCustomSectionLinks(custom),
            title: custom.field_custom_section_label,
            is_visible: custom.field_is_visible,
          });
        });
      }

      detailsFieldsTemp.push({
        field_name: 'details_resources',
        field_subfields:
          resources && resources.length
            ? resources.map((item) => ({
                field_name: 'details_resources:' + item.id,
                icon: item.field_select_icon,
                title: item.field_link_label,
                is_visible: true,
              }))
            : [],
        title: profile.field_resources_section_title || 'Resources',
        is_visible: true,
      });
      detailsFieldsTemp.push({
        field_name: 'details_social_links',
        field_subfields: orderSocials(profile, true),
        title: profile.field_social_links_section_title || 'Social Links',
        is_visible: true,
      });
      detailsFieldsTemp.push({
        field_name: 'details_colleagues',
        field_subfields:
          profile.field_colleagues && profile.field_colleagues.length
            ? profile.field_colleagues.map((item, index) => {
                return {
                  field_name: 'details_colleagues:' + item,
                  title: item,
                  // comment: profile.field_colleagues_comment?.[index] || 'Comment',
                  is_visible: true,
                };
              })
            : [],
        title: profile.field_colleagues_section_title || 'Colleagues',
        is_visible: true,
      });
      // console.log('headerFieldsTemp', headerFieldsTemp)
      setHeaderFields(
        syncOrders(
          headerFieldsTemp,
          fieldOrders?.filter((item) => item?.field_name?.includes('header_')) || []
        )
      );
      // console.log('detailsFieldsTemp', detailsFieldsTemp, 'fieldOrders', fieldOrders, 'headersField', headerFields)
      // console.log('detailsFieldsTemp', detailsFieldsTemp);
      // console.log('fieldOrders', fieldOrders);
      setDetailFields(
        syncOrders(
          detailsFieldsTemp,
          fieldOrders?.filter((item) => item?.field_name?.includes('details_')) || []
        )
      );
    }
  }, [
    profile,
    featuredLinks,
    awardsCertifications,
    repeatablePhoneNumbers,
    repeatableEmailAddress,
    repeatableWebsites,
    resources,
    customSections,
    fieldOrders,
    workScheduleDays,
    languages,
    testimonials,
  ]);

  useEffect(() => {
    if (profile) {
      reFetchProfileData(profile);
    }
  }, [fetchProfileDetails, profile]);

  const updateProfile = useCallback(
    async (
      uid: number,
      body: any,
      paragraphBody?: any,
      callback?: (values: any) => Promise<void>
    ) => {
      try {
        await updateProfileAPI(uid, body);
        if (paragraphBody) {
          await updateParagraphAPI({
            userid: uid,
            field_featured_link: paragraphBody.field_featured_link,
            field_resources: paragraphBody.field_resources,
            field_custom_sections: paragraphBody.field_custom_sections,
            field_awards_cert: paragraphBody.field_awards_cert,
            field_contact_details_numbers:
              paragraphBody.field_contact_details_numbers,
            field_more_contact_emails: paragraphBody.field_more_contact_emails,
            field_contact_detail_websites:
              paragraphBody.field_contact_detail_websites,
            field_campaign_events: paragraphBody.field_campaign_events,
            field_work_schedule_days: paragraphBody.field_work_schedule_days,
            field_languages: paragraphBody.field_languages,
            field_testimonials: paragraphBody.field_testimonials,
          });
          if (paragraphBody.delete_multi && paragraphBody.delete_multi.length) {
            await updateParagraphAPI({
              userid: uid,
              delete_multi: paragraphBody.delete_multi,
            });
          }
        }
        const multiFields = await fetchRepeatableFields(uid);

        const newProfile = await getProfileByUID(`${uid}`);
        setProfile(newProfile);
        fetchProfileImage(uid);
        fetchNamePronunciation(uid);
        if (callback) {
          await callback(multiFields);
        }
        return multiFields;
      } catch (e) {}
    },
    [fetchProfileImage, fetchRepeatableFields, fetchNamePronunciation]
  );
  useEffect(() => {
    // if (caching && profile) {
    // setCaching(false);
    cacheProfileInfo();
    // }
  }, [
    cacheProfileInfo,
    caching,
    profile,
    companyEntity,
    companyEntity?.title,
    companyEntity?.companyLogos,
    // JSON.stringify(companyEntity),
    detailFields,
    headerFields,
    fieldOrders,
    loadingProfile,
    featuredLinks,
    awardsCertifications,
    repeatablePhoneNumbers,
    repeatableEmailAddress,
    repeatableWebsites,
    resources,
    customSections,
    workScheduleDays,
    languages,
    testimonials,
  ]);
  const { activePhoneNumber, activeTextNumber, activeEmail, activeWebsite } =
    useMemo(() => {
      const contactDetails = detailFields.find(
        (item) => item.field_name === 'details_contact_details'
      )?.field_subfields;
      const activePhoneNumber =
        repeatablePhoneNumbers?.find(
          (element) =>
            contactDetails &&
            contactDetails.find(
              (item) => item.field_name.split(':')[1] === element.id
            )
        )?.field_contact_number ?? '';
      const activeTextNumber =
        repeatablePhoneNumbers?.find((element) => element.field_enable_texts)
          ?.field_contact_number ?? '';
      const activeEmail =
        repeatableEmailAddress?.find(
          (element) =>
            contactDetails &&
            contactDetails.find(
              (item) => item.field_name.split(':')[1] === element.id
            )
        )?.field_contact_email ?? '';
      const activeWebsite =
        repeatableWebsites?.find(
          (element) =>
            contactDetails &&
            contactDetails.find(
              (item) => item.field_name.split(':')[1] === element.id
            )
        )?.field_contact_websites ?? '';

      return {
        activePhoneNumber,
        activeTextNumber,
        activeEmail,
        activeWebsite,
      };
    }, [
      repeatablePhoneNumbers,
      repeatableEmailAddress,
      repeatableWebsites,
      detailFields,
    ]);

  return (
    <profileContext.Provider
      value={{
        profile,
        profileImgUrl,
        profileThumbnailUrl,
        companyImgUrl,
        namePronuciationUrl,
        companyLogoSquareUrl,

        featuredLinks,
        resources,
        languages,
        testimonials,
        customSections,
        awardsCertifications,
        repeatablePhoneNumbers,
        repeatableEmailAddress,
        repeatableWebsites,
        loadingProfile,
        setLoadingProfile,
        setProfile,
        updateProfile,
        companyEntity,
        gwalletHeroImgUrl: companyEntity?.gwalletHeroImgUrl || '',
        workScheduleDays: workScheduleDays,

        companyTheme: companyEntity?.theme || {
          ctaColor: DefaultColors.PRIMARY,
          headerBgColor: DefaultColors.PRIMARY,
          headerTextColor: DefaultColors.WHITE,
          headerIconColor: DefaultColors.WHITE,
          primaryColor: DefaultColors.BLACK,
          iconColor: DefaultColors.PRIMARY,
        },
        defaultTheme: {
          ctaColor: DefaultColors.PRIMARY,
          secondaryCtaColor: DefaultColors.PRIMARY,
          headerBgColor: DefaultColors.PRIMARY,
          headerTextColor: DefaultColors.WHITE,
          headerIconColor: DefaultColors.WHITE,
          primaryColor: DefaultColors.BLACK,
          secondaryColor: DefaultColors.BODY_TEXT,
          iconColor: DefaultColors.PRIMARY,
        },

        activePhoneNumber,
        activeTextNumber,
        activeEmail,
        activeWebsite,
        headerFieldsOrder: headerFields,
        detailFieldsOrder: detailFields,
        setDetailFields,
        isDataCached,
        impersonateUserAlias,
        setImpersonateUserAlias,
        showImpersonateUserSnackbar,
        setShowImpersonateUserSnackbar,
        isColleaguesUpdated,
        setIsColleaguesUpdated,
        isProfileImageLoading,
        setIsProfileImageLoading,
      }}
    >
      {children}
    </profileContext.Provider>
  );
};

export const useProfile = () => useContext(profileContext);
