import {
  ChangeEvent,
  PropsWithChildren,
  ReactElement,
  useState,
  useEffect,
} from 'react';
import classNames from 'classnames';
import { Checkbox, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import Modal from 'react-modal';
import { Formik, Form } from 'formik';

import { Button } from './Button';
import { loginEncrypted } from 'apis/auth';
import { getCompanyEntity, getProfileByUID } from 'apis/profile';
import { useProfile } from 'hooks/useProfile';
import { StyledProps } from 'types/styled';
import {
  clearStorageOnLogin,
  getValueFromSession,
  removeValueFromSession,
  setAuthWithExpire,
  setValueInSession,
} from 'utils/storage';
import { reactNativeUserAgent } from 'constants/userAgents';

import { ReactComponent as CloseIcon } from 'images/icons/Close.svg';
import { ReactComponent as AlertIcon } from 'images/icons/Alert.svg';
import { ResetPasswordModal } from './ResetPasswordModal';
import { ConfirmationMessageModal } from './ConfirmationMessage';
import { Link, useSearchParams } from 'react-router-dom';
import {
  checkQCardRNAppDevice,
  postMessageToQCardApp,
} from 'utils/qcardReactNativeHandlers';
import { privacyPolicyLink } from 'utils/links';
import { useAnalytics } from 'pages/AnalyticsPage/hooks/useAnalytics';
import { CustomButtonType } from 'apis/analytics';
import { DefaultColors } from 'constants/colors';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    margin: 20,
    background: theme.palette.common.white,
    boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.15)',
    borderRadius: 16,
    padding: 40,
    '@media screen and (maxHeight: 500px) and (orientation: landscape)': {
      padding: 20,
      margin: '22% 0 20px 0',
    },
    '@media screen and (maxHeight: 390px) and (orientation: landscape)': {
      padding: 20,
      margin: '16% 0 20px 0',
    },
  },
  closeIcon: {
    position: 'absolute',
    right: 24,
    top: 24,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    alignItems: 'center',
    '@media screen and (maxHeight: 480px) and (orientation: landscape)': {
      gap: 10,
    },
  },
  title: {
    '&.MuiTypography-root': {
      marginTop: 40,
      textAlign: 'center',
      '@media screen and (max-width: 468px)': {
        marginTop: 10,
      },
    },
  },
  errorText: {
    '&.MuiTypography-root': {
      width: '100%',
      fontSize: 16,
      '@media screen and (max-width: 370px)': {
        fontSize: 14,
      },
    },
  },
  subHeading: {
    '& a': {
      color: theme.palette.text.primary,
    },
  },
  requestInfoLink: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    textDecoration: 'none',
  },
  forgotPasswordText: {
    display: 'block',
    width: '100%',
    '& a': {
      color: theme.palette.text.primary,
    },
  },
  forgotPassword: {
    '&.MuiButton-root': {
      width: 'auto',

      '& > p': {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '20px',
        color: theme.palette.text.primary,
        textTransform: 'none',
      },
    },
  },
  overlayClass: {
    position: 'fixed',
    inset: '0px',
    backgroundColor: 'rgb(0 0 0 / 50%)',
    overflow: 'auto',
  },
  rememberMe: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,

    '& .MuiCheckbox-root': {
      padding: 0,
    },

    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
      color: theme.palette.grey[50],
    },
    '@media screen and (maxHeight: 480px) and (orientation: landscape)': {
      fontSize: 15,
    },
  },
  rememberMeDescription: {
    paddingLeft: 32,
    textAlign: 'left',
  },
  rememberMeContainer: {
    marginRight: 'auto',
  },
  error: {
    display: 'flex',
    gap: 8,
  },
  privacyPolicyText: {
    color: theme.palette.text.primary,
    textAlign: 'left',
    textDecoration: 'underline',
  },
  '@global': {
    '.no-scroll': {
      overflow: 'hidden !important',
    },
  },
}));

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -45%)',
    border: 'none',
    padding: 'none',
    background: 'transparent',
    borderRadius: 16,
    width: '100%',
    maxWidth: 540,
    '@media screen and (maxHeight: 480px) and (orientation: landscape)': {
      maxHeight: 98,
    },
  },
};

export interface LoginModalProps extends StyledProps {
  isOpen: boolean;
  closeModal?: () => void;
  setShowPage: React.Dispatch<React.SetStateAction<boolean>>;
  redirect?: (alias: string) => void;
}

export const LoginModal = ({
  isOpen,
  closeModal,
  redirect,
  className,
  setShowPage,
}: PropsWithChildren<LoginModalProps>): ReactElement => {
  const [searchParams] = useSearchParams();
  const redirectLink = searchParams.get('redirect') ?? ''; //check for redirect URL Data
  const classes = useStyles();
  const theme = useTheme();
  const [rememberMe, setRememberMe] = useState(
    !!(
      navigator?.userAgent &&
      navigator.userAgent.includes(reactNativeUserAgent) &&
      window?.ReactNativeWebView
    )
  );
  const [resetPass, setResetPass] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { setProfile, defaultTheme } = useProfile();
  const { trackCustomButtonTappedEvent } = useAnalytics(null);

  const [attempts, setAttempts] = useState<number>(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);
  const [lockoutDuration, setLockoutDuration] = useState<number>(30);

  useEffect(() => {
    if (isOpen && attempts === 0) {
      const loginFailedAttempts = Number(
        getValueFromSession('loginFailedAttempts') ?? '0'
      );
      const _attempts = loginFailedAttempts >= 3 ? 0 : loginFailedAttempts;
      setAttempts(_attempts);
      if (loginFailedAttempts !== _attempts) {
        setValueInSession('loginFailedAttempts', _attempts);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    let timerInterval: NodeJS.Timeout;
    if (isButtonDisabled && timer > 0) {
      timerInterval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0 && isButtonDisabled) {
      setError('');
      setIsButtonDisabled(false);
      setAttempts(0);
      setValueInSession('loginFailedAttempts', 0);
    }
    return () => clearInterval(timerInterval);
  }, [isButtonDisabled, timer]);

  useEffect(() => {
    if (resetPass) {
      trackCustomButtonTappedEvent(CustomButtonType.recoveryPassword, {
        recoveryPasswordStepsType: 'Initialized Recovery',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPass]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen]);

  const onSubmit = async (values: any) => {
    setLoading(true);
    const data = await loginEncrypted({
      name: values.email,
      pass: values.password,
    });
    trackCustomButtonTappedEvent(CustomButtonType.authenticate, {
      email: values.email,
      userId: data?.user?.uid || 'anonymous',
    });
    if (data?.user?.uid) {
      setShowPage(false);
      setError('');
      setValueInSession('loginFailedAttempts', null);
      setValueInSession('trackProfiles', null);
      const response = await getProfileByUID(data?.user?.uid);

      if (response?.field_company_entity?.url) {
        const companyData = await getCompanyEntity(
          response.field_company_entity.url
        );
        const company = companyData?.title[0]?.value || '';
        setProfile({
          ...response,
          company,
        });
        setValueInSession('userRoles', data?.user?.roles);
        setLoading(false);
        closeModal && closeModal();

        if (checkQCardRNAppDevice()) {
          postMessageToQCardApp('loggedIn', {
            justLoggedIn: true,
            profileLink: response?.path?.alias,
          });
        }
        setAuthWithExpire({
          value: {
            ...data,
            user: {
              ...data.user,
              alias: response?.path?.alias,
              field_first_name: response?.field_first_name,
              field_last_name: response?.field_last_name,
              company,
            },
          },
          isRemember: rememberMe,
          expireTTL: 2592000,
        });
        if (response) {
          const beforelogin = getValueFromSession('beforelogin');
          await clearStorageOnLogin();
          if (beforelogin || redirectLink) {
            redirect && redirect(beforelogin ?? redirectLink);
            beforelogin && removeValueFromSession('beforelogin');
          } else {
            window.location.href = window.location.origin + response.path?.alias;
          }
        }
      } else {
        setShowPage(true);
        setLoading(false);
        setError('Company data is missing.');
      }
    } else {
      trackCustomButtonTappedEvent(CustomButtonType.authenticateError, {
        email: values.email,
      });
      setLoading(false);
      setShowPage(true);

      if (!!values.email && !!values.password) {
        setAttempts((prev) => prev + 1);
        const _attempts = attempts + 1;
        setValueInSession('loginFailedAttempts', _attempts);
        if (_attempts === 3) {
          setIsButtonDisabled(true);
          setTimer(lockoutDuration);
          setLockoutDuration((prev) => prev * 2);
        }
        const remainingAttempts = 3 - _attempts;
        if (remainingAttempts === 0) {
          setError(`You're temporary locked out`);
        } else {
          setError(
            `The User Email or Password is incorrect. ${remainingAttempts} more attempts remaining until you're temporary locked out`
          );
        }
      } else {
        setError('The User Email or Password is incorrect.');
      }
    }
  };

  const handleRememberMe = (e: ChangeEvent<HTMLInputElement>) => {
    setRememberMe(e.target.checked);
  };

  useEffect(() => {
    if (!isOpen) {
      setError('');
    }
  }, [isOpen]);

  const onPrivacyPolicyClick = () => {
    trackCustomButtonTappedEvent(CustomButtonType.privacyPolicy, {
      buttonSource: 'Login Modal',
    });
  };

  const onRequestInfoClick = () => {
    trackCustomButtonTappedEvent(CustomButtonType.requestInfo);
  };

  const formatTime = (seconds: number): string => {
    const days = Math.floor(seconds / (60 * 60 * 24));
    const hours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    const remainingSeconds = seconds % 60;

    let time = days > 0 ? days + ' days' : '';
    if (hours > 0) {
      time += `${!!time ? ' ' : ''}${hours} hr`;
    }
    if (minutes > 0) {
      time += `${!!time ? ' ' : ''}${minutes} min`;
    }
    if (remainingSeconds > 0) {
      time += `${!!time ? ' ' : ''}${
        remainingSeconds < 10 ? '0' : ''
      }${remainingSeconds} sec`;
    }
    return time;
  };

  return (
    <>
      {!resetPass ? (
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="QRCode Modal"
          ariaHideApp={false}
          overlayClassName={classes.overlayClass}
        >
          <div className={classNames(classes.container, className)}>
            {closeModal && (
              <CloseIcon
                cursor="pointer"
                width={20}
                height={20}
                className={classes.closeIcon}
                onClick={closeModal}
              />
            )}
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              onSubmit={onSubmit}
            >
              {({ values, handleChange, handleBlur }) => (
                <Form className={classes.form}>
                  <Typography
                    fontSize={30}
                    fontWeight={600}
                    lineHeight="40px"
                    color={theme.palette.text.primary}
                    className={classes.title}
                  >
                    Log In to Your QCard
                  </Typography>
                  {!!error && (
                    <div className={classes.error}>
                      <AlertIcon width={24} height={24} />
                      <Typography
                        className={classes.errorText}
                        fontWeight={400}
                        lineHeight="24px"
                        color={theme.palette.error.main}
                      >
                        {error}
                      </Typography>
                    </div>
                  )}
                  <Typography
                    style={{ textAlign: 'center' }}
                    className={classes.requestInfoLink}
                  >
                    Don’t have an account?{' '}
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to="https://docs.google.com/forms/d/e/1FAIpQLSevfqRYxjq2fV7Z6am8k1OtW4dlmylg3oKj_lnx2-3zmLHyig/viewform"
                      style={{ color: defaultTheme?.ctaColor }}
                      className={classes.requestInfoLink}
                      onClick={onRequestInfoClick}
                    >
                      {' '}
                      Request info{' '}
                    </Link>
                  </Typography>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="email"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="password"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <div className={classes.rememberMeContainer}>
                    <div className={classes.rememberMe}>
                      <Checkbox
                        role="checkbox"
                        value={rememberMe}
                        checked={rememberMe}
                        onChange={handleRememberMe}
                        aria-checked={rememberMe}
                      />
                      <Typography
                        fontSize={16}
                        fontWeight={600}
                        lineHeight="24px"
                        color={theme.palette.text.primary}
                      >
                        Remember Me
                      </Typography>
                    </div>
                    <Typography
                      fontSize={14}
                      fontWeight={400}
                      lineHeight="20px"
                      color={theme.palette.text.primary}
                      className={classes.rememberMeDescription}
                    >
                      By checking this box you won't have to sign in as often.
                    </Typography>
                  </div>
                  <Button
                    id="login-button"
                    type="submit"
                    title="LOG IN"
                    loading={loading}
                    disabled={isButtonDisabled}
                    ctaColor={theme.palette.common.white}
                    bgCtaColor={
                      isButtonDisabled ? DefaultColors.GREY : defaultTheme?.ctaColor
                    }
                  />
                  {isButtonDisabled && (
                    <Typography>{`Try again in ${formatTime(timer)}`}</Typography>
                  )}
                  <Button
                    id="forgot-password-button"
                    variant="text"
                    title="Forgot password?"
                    className={classes.forgotPassword}
                    onClick={() => setResetPass(true)}
                  />
                  <Typography
                    fontSize={14}
                    fontWeight={400}
                    lineHeight="24px"
                    className={classes.forgotPasswordText}
                  >
                    <Link
                      to={privacyPolicyLink}
                      target="_blank"
                      onClick={onPrivacyPolicyClick}
                    >
                      Privacy Policy
                    </Link>
                  </Typography>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      ) : showMessage ? (
        <ConfirmationMessageModal
          isOpen={showMessage}
          closeModal={() => {
            trackCustomButtonTappedEvent(CustomButtonType.recoveryPassword, {
              recoveryPasswordStepsType: 'Close Recovery',
            });
            setShowMessage(false);
            setResetPass(false);
          }}
          setShowPage={setLoading}
        ></ConfirmationMessageModal>
      ) : (
        <ResetPasswordModal
          isOpen={resetPass}
          showConfirmationMessage={() => setShowMessage(true)}
          closeModal={() => {
            trackCustomButtonTappedEvent(CustomButtonType.recoveryPassword, {
              recoveryPasswordStepsType: 'Cancel Recovery',
            });
            setResetPass(false);
          }}
        ></ResetPasswordModal>
      )}
    </>
  );
};
