import { useEffect, useState } from 'react';
import { Typography, Autocomplete } from '@mui/material';
import {
  Field,
  FieldProps,
  FormikErrors,
  FormikTouched,
  FormikValues,
} from 'formik';
import { FormValues } from './utils/types';
import { hexToRgbA } from 'utils/string';
import FieldGroup from 'pages/EditQCard/FieldGroup';
import { Company } from 'types/companyDirectory';
import { FormControlInput, TextFieldInput } from 'styles/global';

interface CreateAccountFormProps {
  errors: FormikErrors<FormValues>;
  touched: FormikTouched<FormValues>;
  values: FormikValues;
  companies: Company[] | null;
  selectedCompany: Company | null;
  setSelectedCompany: (company: Company | null) => void;
  ctaColor?: string;
}

const CreateAccountForm = ({
  errors,
  touched,
  values,
  ctaColor,
  companies,
  selectedCompany,
  setSelectedCompany,
}: CreateAccountFormProps) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(selectedCompany?.title ?? '');
  }, [selectedCompany]);

  return (
    <FieldGroup title="Create New Account">
      <Field name="firstName">
        {({ field, form }: FieldProps) => (
          <TextFieldInput
            fullWidth
            ctaColor={ctaColor}
            id="firstName"
            name="firstName"
            label="First Name"
            value={field.value}
            onChange={(e) => {
              form.setFieldValue('firstName', e.target.value);
            }}
            onBlur={field.onBlur}
            error={touched.firstName && !!errors.firstName}
            helperText={touched.firstName && errors.firstName}
          />
        )}
      </Field>
      <Field name="lastName">
        {({ field, form }: FieldProps) => (
          <TextFieldInput
            fullWidth
            ctaColor={ctaColor}
            id="lastName"
            name="lastName"
            label="Last Name"
            value={field.value}
            onChange={(e) => {
              form.setFieldValue('lastName', e.target.value);
            }}
            onBlur={field.onBlur}
            error={touched.lastName && !!errors.lastName}
            helperText={touched.lastName && errors.lastName}
          />
        )}
      </Field>
      <Field name="email">
        {({ field, form }: FieldProps) => (
          <TextFieldInput
            fullWidth
            ctaColor={ctaColor}
            id="email"
            name="email"
            label="Email"
            value={field.value}
            onChange={(e) => {
              form.setFieldValue('email', e.target.value);
            }}
            onBlur={field.onBlur}
            error={touched.email && !!errors.email}
            helperText={touched.email && errors.email}
          />
        )}
      </Field>
      {companies && companies.length > 0 && (
        <Field name="company">
          {({ field, form }: FieldProps) => (
            <FormControlInput fullWidth ctaColor={ctaColor}>
              <Autocomplete
                freeSolo
                options={companies.map(
                  (company) =>
                    new DOMParser().parseFromString(company.title, 'text/html').body
                      .textContent || ''
                )} // Provides dropdown options
                value={inputValue}
                onInputChange={(event, newInputValue) => {
                  let index = companies.findIndex(
                    (company) =>
                      new DOMParser().parseFromString(company.title, 'text/html')
                        .body.textContent === newInputValue
                  );
                  if (index === -1) {
                    form.setFieldValue('company', null);
                    setSelectedCompany(null);
                  } else {
                    form.setFieldValue('company', newInputValue);
                    setSelectedCompany(companies[index]);
                  }
                }}
                onBlur={field.onBlur}
                renderInput={(params) => (
                  <TextFieldInput
                    {...params}
                    label="Select Company"
                    variant="outlined"
                    fullWidth
                    sx={{
                      '&& .Mui-selected': {
                        backgroundColor: hexToRgbA(ctaColor),
                      },
                    }}
                    error={touched.company && !!errors.company}
                  />
                )}
              />
              {touched.company && errors.company && (
                <Typography color="error">{errors.company}</Typography>
              )}
            </FormControlInput>
          )}
        </Field>
      )}
    </FieldGroup>
  );
};

export default CreateAccountForm;
